import React ,{useEffect,useState} from "react";
 
  import { Link, useNavigate } from "react-router-dom";

   import {Url,frontUrl} from '../Frontapi'
 
 import {HeadBg,MidBg,FootBg,NewMidBg,FstLogo,SiteLogoDark} from '../ImagesIcons.js';

// import  ('../../assets/css/frontend/style.css');


const SiteHeader = ( ) => {
  
   


const [contactname,setcontactName]=useState('');

const [contactemail,setcontactEmail]=useState('');
const [message,setMessage]=useState('');



const [offlemail,setOfflemail]=useState('');
const [offlphone,setOfflphone]=useState('');
const [offlmobile,setOfflmobile]=useState('');

const [offladdress,setOffladdress]=useState('');




const Getsettings = async (e) => {

try{


var postdata={getdata:1};

const response = await fetch(Url+"get-front-settings", {
method: "POST",
headers: {
"Content-Type": "application/json",
} ,
body: JSON.stringify({ postdata }),
}).then((response) => response.json())
.then((data) => {
if (data.success) {



var settingsdata=data.data;
setOfflemail(settingsdata.email );
setOfflphone(settingsdata.phone );
setOfflmobile(settingsdata.mobile );


setOffladdress(settingsdata.address+ ', '+settingsdata.city );





} else{
// ;
}


})



} catch (error) {

console.log(error)

}

}



const ScrollToSec = async (id) =>
{
var element = document.getElementById(id);
element?.scrollIntoView({
behavior: 'smooth'
});
}


useEffect(() => {

Getsettings();





}, []);


   

  return (

<div>
 

   <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 d-flex justify-content-md-center justify-content-lg-start">
                            <div>
                                <ul className="d-md-flex text-center">
                                    <li className="top-left"><a href={"mailto:"+offlemail}><span><i
                                                    className="fa-regular fa-envelope top-icon me-1"></i></span>{offlemail}</a>
                                    </li>
                                    <li className="top-left"><a href={"tel:"+offlphone}><span><i
                                                    className="fa-solid fa-phone  top-icon me-1"></i></span>{offlphone}</a>
                                    </li>
                                    <li className="top-left"><a href={"tel:"+offlmobile}><span><i
                                                    className="fa-brands fa-whatsapp  top-icon me-1"></i></span>{offlmobile}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        {/*<div className="col-lg-4 col-md-12 d-flex justify-content-center">
                            <div><label className="text-white">Follow us:</label></div>
                            <div>
                                <i className="fa-brands fa-facebook-f  top-icon"></i>
                                <i className="fa-brands fa-instagram top-icon"></i>
                                <i className="fa-brands fa-twitter top-icon"></i>
                                <i className="fa-brands fa-linkedin-in top-icon"></i>
                                <i className="fa-brands fa-youtube top-icon"></i>

                            </div>
                        </div>*/ }

                    </div>
                </div>
            </div>




            <div className="main-header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg height-auto padding-0">

                        <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                            <a className="navbar-brand ms-2" href={frontUrl}>
                                <img src={SiteLogoDark} className="mx-wd-100 logo-img-wd" />
                            </a>
                        </div>


                        <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span><i className="fa-solid fa-bars toggle-icon"></i></span>
                        </button>


                        <div className="collapse navbar-collapse d-none d-lg-flex " id="navbarNav">
                            <div className="col-8 main-link">
                                <ul className="navbar-nav ">
                                    <li className="nav-item">
                                        <a className="nav-link ft-600 active" href={frontUrl}>Home <span
                                                className="sr-only">(current)</span></a>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link ft-600" to={frontUrl}   >About Us</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link ft-600" to={frontUrl}   >Services</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link ft-600" to={frontUrl}   >Contact Us</Link>
                                    </li>


                                    <li className="nav-item">
                                        <Link className="nav-link ft-600" to={frontUrl+'blog'}>Blog</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="ms-lg-2 ms-md-0">
                                <Link className="amigobutton" to={frontUrl+'account'}>Login / Register</Link>
                            </div>

                        </div>





                        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                                <div className="col-3">
                                    <img className="navbar-brand mx-wd-100 logo-img-wd" src={SiteLogoDark} />
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body ">

                                <div className="navbar-nav col-6 main-link">
                                    <li><a className="nav-item nav-link ft-600 active" href={frontUrl}>Home <span
                                                className="sr-only">(current)</span></a></li>
                                    <li>
                                        <Link className="nav-link ft-600" to={frontUrl} >About Us</Link>
                                    </li>

                                    <li><Link className="nav-item nav-link ft-600" to={frontUrl} >Services</Link></li>
                                    <li><Link className="nav-item nav-link ft-600" to={frontUrl}   >Contact Us</Link></li>

                                    <li>
                                        <Link className="nav-item nav-link ft-600" to={frontUrl+'blog'}>Blog</Link>
                                    </li>
                                </div>


                                <div className="navbar-nav col-lg-3 col-md-12">
                                    <Link className="amigobutton" to={frontUrl+'account'}>Login / Register</Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>





</div>
 
 

  
  );
};
export default SiteHeader;