import React ,{useEffect,useState} from "react";
 
  import { Link, useNavigate } from "react-router-dom";

   import {frontUrl} from '../Frontapi'
 
// import  ('../../assets/css/frontend/style.css');


const SiteFooter = ( ) => {
  
   

   

  return (

<div>

<div className="container cms-sec-foot">
<div className="footer-section">

 <ul className="list-inline text-center">
                 <li className="list-inline-item footer-link border-r-w"><Link   to={ frontUrl  }>Home</Link></li>

                <li className="list-inline-item footer-link border-r-w"><Link   to={ frontUrl+'about-us' }>About Us</Link></li>
                <li className="list-inline-item footer-link border-r-w"><Link  to={frontUrl+'privacy-policy' }>Privacy Policy</Link></li>
             <li className="list-inline-item footer-link border-r-w"><Link    to={frontUrl+'terms-conditions' }>Terms & Conditions</Link></li>
                             <li className="list-inline-item footer-link border-r-w"><Link to={frontUrl+'refund-policy' }>Refund Policy</Link></li>

                <li className="list-inline-item footer-link  "><Link   to={ frontUrl+'contact-us' }>Contact Us</Link></li>

            
            </ul>
            <hr/>
<div className="col-md-12 col-lg-12 row"> 
<div className="col-md-6 col-lg-6"> 
            <p className="copyright">Copyright  © 2025 <a target="_blank" href="https://www.fststeps.com">fststeps.com</a> - All Rights Reserved</p>


</div>
<div className="col-md-6 col-lg-6"> 
            <p className="copyright">Designed and Developed by <a target="_blank" href="https://www.amigoways.com">Amigoways Technologies</a> </p>


</div>

</div>


   </div>

   </div>


</div>
 
 

  
  );
};
export default SiteFooter;