import React ,{useEffect,useState} from "react";
 
import {Redirectlogin,Url,SiteFavicon} from '../Frontapi.js';
 import MetaTags from 'react-meta-tags';

   
 import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

  import  ('./style-f.css');


const Header = ({pageInfo,title,meta_keywords,meta_description}) => {
  

   const [companyname, setCompanyname] = useState("");
   const [metatitle, setMetatitle] = useState("");
   const [metakeywords, setMetakeywords] = useState("");
   const [metadescription, setMetadescription] = useState("");



 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;


              setCompanyname(settingsdata.companyname );
 
 
            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {
      
  }

 } 

const setMetadata =async() => {

if(typeof pageInfo == 'undefined' && typeof title == 'undefined'){
 
}else if(typeof title != 'undefined'){
 

 
     setMetatitle(companyname +' - '+ title)
    


}else{
     
 
   if(pageInfo == 'Login'){
       setMetatitle( "Kids Talent Showcase Registration & Best Teacher/Counselor Enrollment in Mumbai")
       setMetakeywords('Kids Talent Showcase Mumbai, Best Teacher Registration Mumbai, Best Counselor Mumbai, Alfa Kidz Login, Talent Registration Platform, Teacher Recognition Mumbai')
       setMetadescription('Login to Alfa Kidz to register for the Kids Talent Showcase in Mumbai or as the Best Teacher and Counselor in Mumbai. Join a platform dedicated to nurturing young talent and celebrating excellence in teaching and counseling.')
   }
   else if(pageInfo == 'Teacher Register'){
 

       setMetatitle( 'Best Register Teacher and Counselor in Mumbai | '+companyname)
       setMetakeywords('Register Teacher in Mumbai, Register Counselor in Mumbai, Best Teacher Registration Mumbai, Alfa Kidz Teacher Registration, Counselor Registration Mumbai')
       setMetadescription('Looking to register as a teacher or counselor in Mumbai? Alfa Kidz offers a seamless registration process for the best educators and counselors. Join us to shape young minds!')
   }


     else if(pageInfo == 'Student Register'){
       setMetatitle('Join the Kids Talent Showcase in Mumbai | Register Now')
       setMetakeywords('Kids Talent Showcase Mumbai, Mumbai Kids Event Registration, Talent Show for Kids, Kids Competition Mumbai, Alfa Kidz Events')
       setMetadescription("Encourage your child's talent at the Kids Talent Showcase in Mumbai! Register today for an exciting event designed to inspire creativity and confidence. Limited spots available!")
   }else      if(pageInfo == 'Dashboard'){
       setMetatitle(companyname +' - '+ 'Dashboard')
       
       setMetakeywords('Dashboard')
       setMetadescription('Dashboard')
     } 
else      if(pageInfo == 'Home'){
       setMetatitle('AlfaKidz - Kids Social Media in India & Kids Creativity Platform in India| Kids Safe & Educational Networking in India')
         setMetakeywords('Kids social media in India, Child-friendly social network in India, Online safety for kids in India, Kids digital wellness in India, Social-emotional learning platform in India, Kids creativity platform in India, Online talent showcase for kids in India, Kids educational networking in India, Positive online environment for kids in India')
       setMetadescription('AlfaKidz is a child-friendly social network in India, promoting online safety, digital wellness, and social-emotional learning. A secure platform for kids to showcase talent, enhance creativity, and connect in a positive online environment.')
   

   } else      if(pageInfo == 'Contact Us'){
       setMetatitle('Contact Us - Alfa Kidz | Kids Learning Platform in Mumbai for AI & Robotics')
       
       setMetakeywords("Contact Alfa Kidz, kids learning platform Mumbai, AI and Robotics for kids, kids skill development Mumbai, children's online community, tech skills for kids, Mumbai kids program, online activities for kids, school project showcase Mumbai, motivational speakers for children")
       setMetadescription("Get in touch with Alfa Kidz, the leading kids learning platform in Mumbai. Explore AI and Robotics courses, skill development programs, and kids' talent showcases. Contact us to empower your child with growth and innovation.")
     }


     else      if(pageInfo == 'About Us'){


  setMetatitle('About Alfa Kidz: Empowering Kids in Mumbai with AI, Robotics, and Talent Development')
       setMetakeywords("Kids learning platform Mumbai, AI for kids, Robotics for children, Kids talent showcase Mumbai, Skill development for kids, Safe online space for children, Tech skills for kids Mumbai, School project showcase, Motivational programs for kids, Extracurricular activities platform, Growth and development for kids")
       setMetadescription("Discover Alfa Kidz, a leading platform in Mumbai for kids’ learning, AI, robotics, and skill development. We nurture talent, provide a safe online community, and showcase school projects and extracurricular activities for holistic child growth.")


     }
      else      if(pageInfo == 'Privacy Policy'){

          setMetatitle(companyname +' - '+ pageInfo)
   setMetakeywords('Privacy Policy')
       setMetadescription('Privacy Policy')
            }

else      if(pageInfo == 'Terms & Conditions'){

 
         setMetatitle(companyname +' - '+ pageInfo)
   setMetakeywords('Terms & Conditions')
       setMetadescription('Terms & Conditions')
            }



else      if(pageInfo == 'Blog'){

 
         setMetatitle("Alfakidz Blog - Kids Social Media Trends, Safety & Digital Wellness")
   setMetakeywords('kids social media blog, child-friendly social networks, online safety for kids, digital wellness for children, kids creativity platform, best social apps for kids, parenting tips for online safety, kids digital learning, social networking for children, Alfakidz insights')
       setMetadescription('Stay updated with the latest trends in kids social media, online safety, and digital wellness. Explore expert advice, community stories, and child-friendly networks on the Alfakidz Blog.')
            }
else      if(pageInfo == 'Blogdetail'){




setMetatitle("Top 10 Social Networking Apps for Kids in Mumbai - Alfa Kidz")
        setMetakeywords(    "Top social networking apps for kids in Mumbai, child-friendly social media, safe social networks for kids, kids social media apps, online safety for kids, digital wellness for children, kids creativity platform, educational networking for kids, best social apps for kids, Alfakidz social network")


 setMetadescription("Discover the top 10 social networking apps for kids in Mumbai, featuring Alfakidz as the #1 choice for online safety, digital wellness, and creative growth.")
}




   else {
        setMetatitle(companyname +' - '+ pageInfo)
       setMetakeywords(pageInfo)
       setMetadescription(pageInfo)
   }  


   
}


 } 

 useEffect(() => {
 Getsettings();
 
 setMetadata()

  }, [companyname]); 

   

  return (

<div>
   <MetaTags>
     <title>{metatitle}</title>

      

      <link rel="shortcut icon" href={SiteFavicon} />

  
      <meta id="meta-description" name="description" content={metadescription} />
      <meta id="meta-keywords" name="keywords" content={metakeywords} />

 

      <meta id="og-title" property="og:title" content={companyname +' - '+ metatitle} />
      <meta id="og-image" property="og:image" content="" />
   </MetaTags>
    <NotificationContainer/>



 


</div>
 
 

  
  );
};
export default Header;